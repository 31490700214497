import { createRouter, createWebHistory } from 'vue-router';
import Accueil from '@/components/accueil/AccueilMain.vue'
import GrTM from '@/components/groupeTherapeutique/GrTMain.vue'
import Facturation from '@/components/facturation/FacturationMain.vue'
import Emploi from '@/components/emploi/EmploiMain.vue'
import Contact from '@/components/contact/ContactMain.vue'
import priseEnChargeVue from '@/components/pec/priseEnCharge.vue';

const routes = [
    {path: '/', component: Accueil},
    {path: '/accueil', component: Accueil},
    {path: '/gr_therapeutique', component: GrTM},
    {path: '/facturation', component: Facturation},
    {path: '/emploi', component: Emploi},
    {path: '/contact', component: Contact},
    {path: '/pec', component: priseEnChargeVue},
    {path: '/:pathMatch(.*)*', redirect: '/accueil'}
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;