<template>
    <MainHeader/>
    <div animated fade class="main">
        <div v-if="message" id="successMessage" class="">
            <p>{{message}}</p>
            <img src="@/assets/emote/close.svg"  @click="closeMessage" alt="close">
        </div>
        <div class="title">
            <img src="@/assets/emote/emoticon_b.svg" alt="logo" >
            <h1>Demande de prise en charge</h1>
        </div>
        <div class="contenu">
            <form ref="form" @submit.prevent="submitForm">
                <div class="information">
                    <div class="detail half">
                        <label for="name">Nom</label>
                        <input type="text" id="nom" name="nom" placeholder="Nom" required>
                    </div>
                    <div class="detail half">
                        <label for="surname">Prénom</label>
                        <input type="text" id="surname" name="prenom" placeholder="Prénom" required>
                    </div>
                    <div class="detail">
                        <label for="dob">Date de naissance</label>
                        <!-- three input day month and year -->
                        <div class="dob">
                            <input ref="dob" 
                                type="text" pattern="[0-9]*" maxlength="2" size="2" 
                                name="jour"  id="jour" placeholder="Jour"
                                required @input="handleInput"
                            >
                            <input ref="dob" 
                                type="text" pattern="[0-9]*" maxlength="2" size="2" 
                                id="mois" name="mois"  placeholder="Mois"
                                required @input="handleInput"    
                            >
                            <input ref="dob" 
                                type="text" pattern="[0-9]*" maxlength="4" size="4" 
                                id="annee" name="annee" class="bigger"  placeholder="Année"
                                required @input="handleInput"    
                            >
                        </div>
                    </div>
                    <div class="detail">
                        <label for="med">Médecin traitant</label>
                        <input type="text" id="med" name="med" placeholder="Dr ..." required>
                    </div>
                    <div class="detail">
                        <label for="email">Email</label>
                        <input type="email" id="email" name="email" placeholder="Email@email.ch" required>
                    </div>
                    <div class="detail">
                        <label for="phone">Téléphone</label>
                        <input type="tel" id="phone" name="phone" placeholder="Téléphone" required>
                    </div>
                </div>
                <div class="detailSubmit">
                    <div class="detail">
                        <label for="motif">Motif</label>
                        <textarea id="motif"  name="motif" required></textarea>
                    </div>
                    <button type="submit">
                        <!-- if loading then loadind cinematic -->
                        <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                        <span v-else>Envoyer</span>
                    </button>
                </div>
            </form>
        </div >
    </div>
    <MainFooter/>
</template>

<script>
    import MainHeader from '../global/MainHeader.vue';
    import MainFooter from '../global/MainFooter.vue';
    import axios from 'axios';
    export default {
        name: 'PriseEnCharge',
        data() {
            return {
                loading: false,
                message : '',
                color : 'green',
                bgColor: 'lightgreen'
            }
        },
        methods: {
            closeMessage(){
                this.message = '';
            },
            submitForm() {
                const form = this.$refs.form;
                const formData = new FormData(form);

            // to dict object
                const object = {};
                formData.forEach((value, key) => {
                    object[key] = value;
                });

                this.loading = true;
                axios.post(process.env.VUE_APP_API_HOST+'/pec', object)
                    .then(res => {
                        res.data;
                        form.reset();
                        this.message = 'Votre demande a bien été envoyée';
                        this.color = '#3c763d';
                        this.bgColor = '#dff0d8';
                        this.loading = false;
                    })
                    .catch(err => {
                        console.log(err);
                        this.color = '#a94442';
                        this.bgColor = '#f2dede';
                        this.message = 'Une erreur est survenue';
                        this.loading = false;
                    })

            },

            handleInput(e){
                const input = e.target;
                
                // if input does not respect parttern  dont allow input
                input.value = input.value.match(input.pattern)[0];

                // if input is full and has a next sibling focus on it
                if( input.nextElementSibling && input.value.length >= input.maxLength)
                    input.nextElementSibling.focus();
            }
        },
        components: {
            MainHeader,
            MainFooter
        }
    }

</script>


<style scoped>
    div.main {
        height: 100%;
        width: 100%;
        background: #49584C;
        color: white;
        padding: 3em;

    }

    div.contenu {
        height: 100%;
    }

    div.title {
        display: flex;
        align-items: center;
        justify-content: left;
        flex-direction: row;
        margin: 20px 0;
    }

    div.title img {
        width: 50px;
        height: 50px;
    }

    div.title h1 {
        font-size: 2em;
        margin-left: 10px;
        font-family: 'Sequel Sans';
        font-style: normal;
        font-weight: 420;
        font-size: 40px;
        line-height: 47px;
    }

    form {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 57.5% 37.5%;
        gap: 5%;
    }

    input{
        height: 100%;
        /* border radius left 5px */
        border-radius: 10px;
        border: none;
        padding-left: 10px;
        min-height: 64px;
    }

    /* input texte */
    textarea{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    div.detailSubmit div.detail {
        height: 80%;
        justify-content: start;
    }

    div.detail {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px 0;
        position: relative;
        width: 100%;
    }

    div.detail.half +  div.detail.half {
        margin-left: 5%;
    }

    .half {
        width: 47.5% !important;
    }

    div.detail label {
        font-family: 'Archia';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    }

    button {
        height: 15%;
        width: 60%;
        float: right;
        border: none;
        background: #7CBD8A;
        background: linear-gradient(to left, #7CBD8A 50%, #426b4b 50%) right;
        background-size: 200% 100%;
        color: white;
        font-weight: bold;
        /* border radius left 5px */
        border-radius: 10px;
        transition: background-position 0.5s;
    }

    /* on hover transition to draker color from left to right */
    button:hover {
        background-position: left;
        transition: background-position 0.5s;
    }

    div.dob{
        display: flex;
        justify-content: space-between;
        gap: 1em;
    }

    div.dob input{
        flex: 2;
    }

    .bigger{
        flex: 3 !important;
    }

    #successMessage {
        background-color: v-bind(bgColor);
        color: v-bind(color);
        padding: 10px;
        margin-top: 10px;
        border-radius: 5px;
        width: 80%;
        margin: auto;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #successMessage img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    #successMessage p {
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        form {
            display: flex;
            flex-direction: column;
            gap: 1em;
        }
        div.detailSubmit div.detail {
            height: 100%;
        }
        div.detail.half +  div.detail.half {
            margin-left: 0;
        }
        .half {
            width: 100% !important;
        }

        button {
            width: 100%;
        }

        div.dob{
            flex-direction: column;
            gap: 1em;
        }

        div.title h1{
            font-size: 1.2em;

        }
        div.title img {
            width: 30px;
            height: 30px;
        }

        textarea{
            min-height: 150px;
        }

        button{
            height: 3em;
        }
        
    }
</style>