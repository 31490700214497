<template>
    <div class="main_question">
        <div class="quest">
            <h1>{{ question }}</h1> 
            <img ref="button" src="@/assets/emote/plus.svg" @click="toggle" alt="">
        </div>
        <div ref="response" class="response hidden" >
            <p>{{ response }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'QuestionsLine',
        props: {
            question: String,
            response: String
        },
        data() {
            return {
                plus: require('@/assets/emote/plus.svg'),
                minus: require('@/assets/emote/minus.svg')
            }
        },
        methods: {
            toggle(){
                let img = this.$refs.button;
                let response = this.$refs.response;
                // get height of the response p child
                let height = response.children[0].offsetHeight +30;
                // add 1em padding


                console.log(height);
                // if the response is hidden
                if(response.classList.contains('hidden')){
                    response.style.height = `${height}px`;
                    response.classList.remove('hidden');
                    img.src = this.minus;
                    return
                }
                // if the response is visible
                response.style.height = 0 + 'px'; 
                response.classList.add('hidden');
                img.src = this.plus;
            }
        },
        computed: {
        }
    }

</script>


<style scoped>
    .main_question{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1em 0;
    }
    .quest{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
    }

    .quest h1{
        font-family: 'Archia', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        margin-right: 1em;
    }

    .quest img{
        width: 1em;
        height: 1em;
        cursor: pointer;
        line-height: 24px;
        margin-bottom: 0.5rem;
    }

    .response.hidden{
        height: 0;
        border: 1px solid #BEBEBE;
        transition: all 0.5s ease-in-out;
        padding: 0;
        overflow: hidden;
    }   
    .response{
        overflow: hidden;
        width: 100%;
        padding: 15px;
        transition: all 0.5s ease-in-out;
    }

    .response p{
        font-family: 'Archia';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #A5A5A5;
        margin: 0;
    }
</style>