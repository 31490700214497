<template>
    <Header/>
    <div class="content">
        <SliderAccueil/>
        <QsnAccueil/>
        <PrestationAccueil/>
    </div>
    <Footer/>
</template>

<script>
import SliderAccueil from './parts/SliderAccueil.vue'
import QsnAccueil from './parts/QsnAccueil.vue'
import PrestationAccueil from './parts/PrestationAccueil.vue'
import Footer from '../global/MainFooter.vue'
import Header from '../global/MainHeader.vue'

export default {
    name: 'accueilMain',
    data() {
        return {
            //
        }
    },
    methods: {
        //
    },
    components: {
        SliderAccueil,
        QsnAccueil,
        PrestationAccueil,
        Footer,
        Header
    }
}
</script>