<template>
   <div class="main">
        <div fade animated class="left">
            <img  src="@/assets/logo.png" alt="" class="logo">
            <a href="/pec"><button class="big large">Demander une prise en charge</button></a>
        </div>
        <img ref="emo" animated fade src="@/assets/emote/emoticon_a.svg" alt="" class="emo">
        <div animated fromCornerTop class="poster"></div>
        <div animated fromCornerBot class="morceau">
            <h1 class="larger">
                Organistion d'aide et de soins à domicile specialisée en psychiatrie
            </h1>
        </div>
        
   </div>
</template>

<script>
    export default {
        name: 'SliderAccueil',
        data() {
            return {
            }
        },
        computed: {
        }
    }

</script>

<style scoped>



div.main{
    height: fit-content;
    position: relative;
    margin-left: 5em;
    padding-bottom: 10em;
    margin-bottom: 9em;
}
img.logo{
    width: 75%;
    display: block;
}

div.morceau{
    width: 55%;
    height: fit-content;
    border: none;
    border-radius: 30px;
    position: absolute;
    left: 0;
    bottom: -10%;
    text-emphasis: none;
    text-align: left;
    color: black;
    background: white;
    padding: 2em;
}

div.morceau h1{
    text-align: left;
}

button.big{
    width: 100%;
    background: #7CBD8A;
    border: none;
    color: white;
    font-size: 0.9em;
    border-radius: 10px;
    padding: 1em 2em;
}

div.left{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 5em;

}

img.emo{
    position: absolute;
    top: 0;
    left: calc(25%  + 5em);
    width: 5em;
    animation: float 3s infinite, circlularMove 3s infinite;
}

div.poster {
    width: calc(75% - 7.5em);
    height: 100%;
    background: url('@/assets/background/plants.jpeg') no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    /* border radius left 10 px */
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

/* animation float in 2d */

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

@media only screen and (max-device-width: 600px ){
    img.logo{
        display: none;
    }
    div.main{
        margin-left: 0;
        margin-bottom: 4em;
        padding-bottom: 0;
        height: 40vh;
    }

    div.left {
        position: absolute;
        width: 80%;
        bottom: -10%;
        z-index: 9;    
        background: white;
        padding: 1em;
        border-radius: 10px;
    }
    div.morceau{
        position: relative;
        width: 100%;
        border-radius: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    div.morceau h1{
        text-align: center;
        font-size: 1.2em;
    }

    div.poster {
        bottom: 0;
        height: 70%;
        width: 75%;
        top : auto;
        border-radius: 10px;
    }
    img.emo{
        width: 2em;
        top: 30%;
        left: calc(25% - 1em);
    }
}
</style>