
<template>
    <div class="card">
        <div class="card-header">
            <h3>{{ job.title }}</h3>
        </div>
        <div class="card-body">
            <h4 class="disp">Description</h4>
            <p class="disp">{{ job.description }}</p>
            <h4>Votre profil</h4>
            <p>{{ job.requirements }}</p>
            <h4>Informations</h4>
            <p><strong>Contrat</strong> {{ job.contract }}</p>
            <p><strong>Taux</strong> {{ job.taux }}</p>
            <p><strong>Lieu</strong> {{ job.location }}</p>
            <p><strong>Contact</strong> {{ job.contact }}</p>
            <p><strong>Email</strong> {{ job.email }}</p>
            <p><strong>Téléphone</strong> {{ job.phone }}</p>
        </div>
    </div>

</template>

<script>
export default {
    name: 'EmploiCard',
    props: {
        job: Object
    },
}
</script>

<style scoped>


/* div.card-body > :not(.disp) {
    display: none;
}

div.card:hover div.card-body > :not(.disp){
    display: block !important;
}
div.card:hover {
    flex: 1;
} */

div.card {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
    padding: 2vh;
    overflow: hidden;
    transform: scale(0.95);
    transition: 0.5s;
    border-radius: 10px;
    flex: 0.5;
    min-width : 40%;
    max-height: fit-content;

    transition: all 0.5s;
}

div.card:hover {
    transform: scale(1);
}

div.card > div.card-header {
    background-color: var(--grey-green);
    color: white;
    padding: 2vh;
    border-radius: 10px 10px 0 0;
}

div.card > div.card-header > h3 {
    font-weight: 900;
}

div.card > div.card-body {
    text-align: left;
    padding: 2vh;
}

div.card > div.card-body > p {
    margin-bottom: 1vh;
}

div.card > div.card-body > p > strong {
    font-weight: 900;
}

div.card > div.card-body > p > strong::after {
    content: ':';
}

div.card > div.card-body > p > strong::before {
    content: ' ';
}

div.card > div.card-body > p > strong::before {
    content: ' ';
}

/* phone */
@media (max-width: 768px) {
    div.card {
        flex: 1;
    }
}

</style>