<template>
    <div animated scaleUp class="nsletter" >
        <h1>Newsletter</h1>
        <p>Abonnez-vous à notre newsletter pour être informé(e) des cours collectifs</p>
        <div class="subscribe">
            <input type="text" placeholder="Entrez votre adresse mail">
            <button>S'abonner</button>
        </div>
    </div>
</template>

<script> 
    export default {
        name: 'NewsLetter',
        data() {
            return {
                //
            }
        },
        methods: {
            //
        }
    }
</script>

<style scoped>
    div.nsletter{
        height: fit-content;
        width: 60%;
        margin : 0 auto;
        margin-bottom: 5em;
        background: #49584C;
        border-radius: 10px;
        padding: 2em;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        gap: 1em;
        position: relative;
    }
    .subscribe{
        display: flex;
        justify-content: center;
        width: 80%;
        height: 3em;
    }
    input{
        width: 50%;
        height: 100%;
        /* border radius left 5px */
        border-radius: 5px 0 0 5px;
        border: none;
        padding-left: 10px;
    }

    /* center place holder */
    input::placeholder{
        text-align: center;
    }

    button{
        width: 50%;
        height: 100%;
        border: none;
        background: #7CBD8A;
        background: linear-gradient(to left, #7CBD8A 50%, #426b4b 50%) right;
        background-size: 200% 100%;
        color: white;
        font-weight: bold;
         /* border radius left 5px */
        border-radius: 0 5px 5px 0;
        transition: background-position 0.5s;
    }

    /* on hover transition to draker color from left to right */
    button:hover{
        background-position: left;
        transition: background-position 0.5s;
    }

    /* remove focus style */
    button:focus{
        outline: none;
    }

    input:focus{
        outline: none;
    }

    p{
        text-align: center;
        font-family: 'Archia';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
    }

    @media (max-width: 768px){
        div.nsletter{
            width: 100%;
            border-radius: 0;
        }

        .subscribe{
            width: 100%;
        }
        input{
            width: 100%;
            border-radius: 5px 0 0 5px;
        }
    }
</style>