<template>
    <MainHeader/>
    <div class="content">
        <VerticalBarGrT/>
        <div class="container">
            <div class="grsection">
                <h1>Estime de soi</h1>
                <img src="@/assets/illustration/eds.webp" class="bot-img"  alt="Groupe de personnes qui discute">
                <div class=wrapper>
                    <div class="description">
                        <p>
                        Le groupe thérapeutique "Estime de soi" est destiné aux personnes ayant une baisse d'estime de soi. 
                        Il propose des outils pour rétablir l'estime de soi, la confiance en soi, l'amour pour soi et la capacité à s'affirmer. 
                        Les participants sont invités à participer activement aux séances et à effectuer des exercices à la maison. 
                        Le groupe comporte des notions théoriques et des exercices spécifiques pour atteindre des objectifs tels que 
                        l'identification des joies de la vie quotidienne, l'écoute de ses besoins, la reconnaissance des qualités
                        </p>
                    </div>
                    <div class="infos">
                        <p><i class="material-icons">place</i> Rue du marché 4 / 2300 La Chaux-de-Fonds </p>
                        <p><i class="material-icons">group</i> 4 à 6 personnes</p>
                        <p><i class="material-icons">calendar_view_month</i> 30 séances</p>
                        <p><i class="material-icons">phone</i> 079 235 54 26 </p>
                    </div>
                </div>
            </div>
            <div class="grsection">
                <h1>Gestion des émotions</h1>
                <img src="@/assets/illustration/gde.png" class="bot-img"  alt="Groupe de personnes qui discute">
                <div class=wrapper>
                    <div class="description">
                        <p>
                        Le groupe thérapeutique "Gestion des émotions" est destiné aux personnes ayant des difficultés à 
                        réguler leurs émotions et souhaitant améliorer la gestion de celles-ci. Il propose des outils pour mieux comprendre 
                        et gérer ses émotions, avec une participation active requise durant les séances et à la maison. Il comporte des notions 
                        théoriques et des exercices pour développer des compétences de gestion des émotions, ainsi que des informations, vidéos, 
                        témoignages et une séance de relaxation guidée
                        </p>
                    </div>
                    <div class="infos">
                        <p><i class="material-icons">place</i> Rue du marché 4 / 2300 La Chaux-de-Fonds </p>
                        <p><i class="material-icons">group</i> 4 à 6 personnes</p>
                        <p><i class="material-icons">calendar_view_month</i> 20 séances</p>
                        <p><i class="material-icons">phone</i> 079 235 54 26 </p>
                    </div>
                </div>
            </div>
            <div class="grsection">
                <h1>Education Thérapeutique</h1>
                <img src="@/assets/illustration/et.png" class="bot-img"  alt="Groupe de personnes qui discute">
                <div class=wrapper>
                    <div class="description">
                        <p>
                        Le groupe thérapeutique "Education Thérapeutique" est destiné aux personnes souhaitant améliorer leur compréhension de 
                        ùleur maladie et de leurs traitements, ainsi que leur qualité de vie. Il propose des exercices pour devenir plus acteur 
                        de sa prise en charge, comprendre son corps et sa maladie, repérer les signes d'alerte, gérer la maladie et son quotidien, 
                        utiliser les ressources du système de soin et faire valoir ses droits. Les participants sont invités à participer activement 
                        aux séances et à réaliser un plan de crise conjoint
                        </p>
                    </div>
                    <div class="infos">
                        <p><i class="material-icons">place</i> Rue du marché 4 / 2300 La Chaux-de-Fonds </p>
                        <p><i class="material-icons">group</i> 4 à 6 personnes</p>
                        <p><i class="material-icons">calendar_view_month</i> 7 séances</p>
                        <p><i class="material-icons">phone</i> 079 235 54 26 </p>
                    </div>
                </div>
            </div>
            <div class="grsection">
                <h1>La Libre parole</h1>
                <img src="@/assets/illustration/grt-bg.jpg" class="bot-img"  alt="Groupe de personnes qui discute">
                <div class=wrapper>
                    <div class="description">
                        <p>
                        Le groupe thérapeutique "La Libre parole" est un espace ouvert pour tous ceux qui souhaitent s'exprimer et partager dans un cadre protégé et sans jugement. 
                        Il propose un temps d'écoute et d'expression, où les participants peuvent exprimer leurs questionnements, besoins et vécus, et réfléchir ensemble. 
                        Les règles du groupe incluent le respect de la parole des autres, la ponctualité, la confidentialité, la non-interruption et la discrétion
                        </p>
                    </div>
                    <div class="infos">
                        <p><i class="material-icons">place</i> Rue du marché 4 / 2300 La Chaux-de-Fonds </p>
                        <p><i class="material-icons">group</i> 4 à 6 personnes</p>
                        <p><i class="material-icons">phone</i> 079 235 54 26 </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <MainFooter/>
</template>

<script>
import MainHeader from '../global/MainHeader.vue';
import MainFooter from '../global/MainFooter.vue';
import VerticalBarGrT from './parts/VerticalBarGrT.vue';

    export default {
        name: 'GrTMain',
        data() {
            return {
                //
            }
        },
        methods: {
            //
        },
        components: {
            MainHeader,
            MainFooter,
            VerticalBarGrT
        }
    }
</script>

<style scoped>
   .wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 40px;
        min-height: 400px;
    }

    .description{
        width: max-content;
        flex-basis: 0;
        flex-grow: 1;
        text-align: justify;
    }

    .infos{
        width: max-content;
        flex-basis: 0;
        flex-grow: 1;
    }

    .content{
        background: var(--white);
        display: flex;
        flex-direction: row;
        margin-top: 50px;
        padding-bottom: 200px;
    }

    div.grsection{
        min-height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    div.grsection img{
        max-height: 300px;
        object-fit: contain;
    }

    
    @media only screen and (max-device-width: 600px ){

        div.content{
            overflow: hidden;
        }   
        div.grsection{
            min-height: 1200px;
            font-size: small;
           
        }
        .wrapper{
            flex-direction: column;
        }
        .description{
            width: 100%;
            gap: 0px;
        }

        h1{
            font-size: 1.2rem;
        }
        
        div.container{
            width: calc(100% - 80px);
        }

        .content{
            flex-direction: column;
            margin-top: 0px;
            padding-bottom: 0px;
        }
        
    }
</style>