<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
    //
  },
  methods: {
    handleScroll() {
        // get all elements with the property fade
        const elements = document.querySelectorAll('[animated]');
        elements.forEach((element) => {
            // get the top of the element
            let top = element.getBoundingClientRect().top;

            // fadedown and no class visible
            if (element.hasAttribute('fadeup') && !element.classList.contains('visible') ){
                console.log('fadeUp');
                top -= element.getBoundingClientRect().height;
            }

            if (top < window.innerHeight){
                element.classList.add('visible');
            }else{
                element.classList.remove('visible');
            }
        });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    setTimeout(() => {
        this.handleScroll();
    }, 500);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,800&display=swap');

/* import font from file */
@font-face {
    font-family: 'Sequel Sans';
    src: url('./assets/fonts/Sequel/Sequel Sans OTF/Sequel Sans Semi Bold Disp.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Archia';
    src: url('./assets/fonts/Archia/archia-regular-webfont.eot');
    src: url('./assets/fonts/Archia/archia-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/Archia/archia-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/Archia/archia-regular-webfont.woff') format('woff'),
         url('./assets/fonts/Archia/archia-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

[fade]{
    transition: opacity 0.5s;
}

[fade]:not(.visible){
    opacity: 0;
}

[fromCornerBot]{
    transition: transform 0.5s;
}

[fromCornerBot]:not(.visible){
    opacity: 0;
    transform: translate(-100%, 100%);
}

[fromCornerTop]{
    transition: transform 0.5s;
}

[fromCornerTop]:not(.visible){
    opacity: 0;
    transform: translate(100%, -100%);
}

[fadeUp]{
    transition: opacity 0.2s, transform 0.5s;
}

[fadeUp]:not(.visible){
    opacity: 0;
    transform: translateY(100%) !important;
}

[fadeDown]{
    transition: opacity 0.2s, transform 0.5s;
}

[fadeDown]:not(.visible){
    opacity: 0;
    transform: translateY(-100%) !important;
}

[fadeLeft]{
    transition: opacity 0.2s, transform 0.5s;
}

[fadeLeft]:not(.visible){
    opacity: 0;
    transform: translateX(-100%) !important;
}

[fadeRight]{
    transition: opacity 0.2s, transform 0.5s;
}

[fadeRight]:not(.visible){
    opacity: 0;
    transform: translateX(100%) !important;
}

[scaleUp]{
    transition: transform 0.5s;
}

[scaleUp]:not(.visible){
    transform: scale(0) !important;
}



:root {
    --green: #5a9b5c;
    --white: #fff;
    --black: #1E1E2F;
    --grey-green: rgb(69, 82, 77);
  }

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 55vh;
}

body{
    font-family: 'Sequel Sans', sans-serif;
    background-color: var(--white);
    color: var(--black);
    margin: 0px;
    padding: 0px;

    display: flex;
    min-height: 100vh;
    flex-direction: column;

    font-size: large;
}
body div.content {
    flex: 1;
}

ul{
    list-style: none;
}

a {
    color: inherit;
    text-decoration: inherit;
}

a:hover {
    color: inherit;
    text-decoration: inherit;
}

h1{
    text-align: center;
}

#app{
    overflow: hidden;
}

/* remove scrollbar from #app */
#app::-webkit-scrollbar {
    display: none;
}

/* add effect on body scroll bar */
body::-webkit-scrollbar {
   display: none;
}


@media screen and (max-width: 600px) {
  body{
        font-size: 1em;
    }
  
}
</style>
