<template>
    <MainHeader/>
    <div class="content">
        <div id="emploi" class="container">
            <h1>Poste à pourvoir</h1>
            <hr>
            <div v-if="jobs.length" class="cardList">
                <div class="wrapper">
                    <EmploiCard v-for="job in jobs" :key="job.title" :job="job"/>
                </div>
            </div>
            <h3 v-else style="text-align: center;">Aucun poste à pourvoir pour le moment.</h3>
        </div>
    </div>
<MainFooter/>
</template>

<script>

import MainHeader from '../global/MainHeader.vue';
import MainFooter from '../global/MainFooter.vue';
import EmploiCard from './parts/EmploiCard.vue';

export default {
    name: 'EmploiMain',
    data() {
        return {
            jobs : [
//                 {
//                     title: 'Infirmier en psychiatrie (h/f)',
//                     description: `
//                     Vous êtes au bénéfice d’un diplôme en soins infirmiers suisse ou d’une reconnaissance de 
//                     diplôme de la Croix Rouge Suisse.Vous aimez travailler en équipe, prendre des initiatives 
//                     et souhaitez offrir des soins ambulatoires de qualité centré sur les besoins réels des 
//                     patients dont vous aurez la référence.
//                     `,
//                     requirements: `Nous recherchons un(e) infirmier(ère) Bachelor/HES ou avec un diplôme reconnu par la CRS, bénéficiant d'au moins 2 ans d'expérience en psychiatrie. Vous devez avoir une très bonne maîtrise des outils bureautiques et informatiques courants, ainsi qu'une aptitude organisationnelle et relationnelle avec les patients et les partenaires de soins. La flexibilité et l'autonomie sont des qualités essentielles.

// Vos missions comprendront l'évaluation et le suivi des besoins des patients, la préparation et l'administration des traitements, l'accompagnement lors d'activités quotidiennes, ainsi que la conduite d'entretiens d'évaluation avec les patients.`,
//                     contract: 'À plein temps, À temps partiel et Travail temporaire',
//                     location: 'La Chaux-de-Fonds',
//                     contact: 'Alexandra Miceli',
//                     email: 'alexandra.miceli@falcare.ch',
//                     taux: '80-100%',
//                     phone: '+41 77 232 32 11'
//                 },
//                 {
//                     title: 'Auxiliaire de santé CRS, Auxiliaire de vie, Aide-soignante',
//                     description: `Si vous bénéficiez d’une expérience professionnelle correspondant au profil recherché et que vous partagez nos valeurs qui sont le professionnalisme, la reconnaissance, l’ouverture d’esprit, la solidarité, le respect et la confiance, nous vous invitons à déposer votre dossier de candidature complet.

// Nous offrons : une activité motivante au sein d’une équipe engagée avec d’importantes responsabilités. De plus, notre politique de formation favorise le développement de compétences et nous offrons des prestations sociales de qualité.
// Taux d’activité : horaire ou mensuel / contrat à durée indéterminée
// `,
//                     requirements: `Nous recherchons des auxiliaires de santé certifiés avec un minimum de 120 heures de formation par la Croix-Rouge suisse ou une certification jugée équivalente. Idéalement, vous avez une expérience dans le domaine de la santé mentale et/ou des soins à domicile, et vous êtes passionné par la psychiatrie et le maintien à domicile. L'autonomie est une qualité recherchée, tout en travaillant en collaboration avec une équipe pluridisciplinaire. Les missions incluent la guidance au ménage au domicile des personnes fragiles sur le plan psychique pour favoriser leur autonomie. Vous aurez des horaires fixes et une autonomie dans la planification des rencontres avec les patients. Un permis de conduire est indispensable.`,
//                     contract: 'À temps partiel et Travail temporaire',
//                     location: 'Chaux de Fonds / Neuchâtel',
//                     contact: 'Alexandra Miceli',
//                     taux: '80-100%',
//                     email: 'alexandra.miceli@falcare.ch',
//                     phone: '+41 77 232 32 11'
//                 },
//                 {
//                     title: 'Assistante sociale en institution de soins ambulatoires',
//                     description: `Notre institution recherche une assistante sociale passionnée par le domaine de la santé mentale. En tant qu’assistante sociale en institution de soins ambulatoires, vous jouerez un rôle essentiel dans l’accompagnement des patients souffrant de troubles psychiques. `,
//                     requirements: `Nous recherchons un.e assistant.e social.e passionné.e par la santé mentale pour un poste clé en institution de soins ambulatoires. Vos responsabilités incluront l'évaluation des besoins sociaux des patients, la collaboration avec une équipe multidisciplinaire, le suivi des dossiers sociaux et administratifs, ainsi que la facilitation de l'accès aux prestations sociales et aux ressources communautaires. Les qualifications requises comprennent un Bâchelor en travail social ou équivalent, une expérience dans les soins de santé mentale, des compétences en communication et en travail d'équipe. Le poste offre un contrat à durée indéterminée avec une rémunération conforme aux normes en vigueur.`,
//                     contract: 'À plein temps, À temps partiel et Travail temporaire',
//                     location: 'Chaux de Fonds / Neuchâtel',
//                     contact: 'Alexandra Miceli',
//                     taux: '60%',
//                     email: 'alexandra.miceli@falcare.ch',
//                     phone: '+41 77 232 32 11'
//                 },
//                 {
//                     title: 'Secrétaire médicale en institution de soins ambulatoires',
//                     description: `Nous recherchons actuellement une secrétaire médicale dynamique et attentionnée pour rejoindre notre équipe dans les soins ambulatoires en santé mentale. En tant que secrétaire médicale, vous serez le premier point de contact pour nos patients et contribuerez à assurer un flux de travail efficace au sein de notre clinique. Vos responsabilités incluront la gestion des rendez-vous, l'accueil des patients, la tenue des dossiers médicaux et la coordination des communications entre les patients, le personnel médical et les intervenants externes.`,
//                     requirements: `Nous recherchons un.e secrétaire médical.e pour notre clinique. Vous accueillerez les patients, gérerez les rendez-vous et les dossiers médicaux électroniques, coordonnerez les communications et aiderez à maintenir le bon fonctionnement de la clinique. Qualifications : expérience en secrétariat médical, excellentes compétences en communication, capacité à travailler en équipe, maîtrise des outils informatiques et sens de l'organisation. Confidentialité et respect des normes éthiques sont essentiels.`,
//                     contract: 'À temps partiel et Travail temporaire',
//                     location: 'Chaux de Fonds / Neuchâtel',
//                     contact: 'Alexandra Miceli',
//                     taux: '40%',
//                     email: 'alexandra.miceli@falcare.ch',
//                     phone: '+41 77 232 32 11'
//                 }
            ]
        }
    },
    methods: {
        //
    },
    components: {
        MainHeader,
        MainFooter,
        EmploiCard
    }
}
</script>

<style scoped>
div.content {
  background-color: #49584C;;
}

div#emploi {
   margin-top: 5vh;
   margin-bottom: 10vh;
}

div#emploi > div.wrapper{
    display: flex;
    column-gap: 2vh;
}

div#emploi > div.wrapper > div{
    flex: calc(50% -2vh/2);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
    padding: 2vh;
    overflow: hidden;
    transform: scale(0.95);
    transition: 0.5s;
    border-radius: 10px;
}

div#emploi > div.wrapper > div:hover{
    transform: scale(1);
}

div#emploi > div.wrapper > div > h2{
    color: var(--grey-green);
    font-weight: 900;
}

div#emploi > div.wrapper > div > h3{
    text-decoration: underline;
}

div.container{
    min-width: clamp(300px, 90%, 2000px);
}


div.cardList{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 2vh;
}

div.cardList > div.wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 2vh;
    row-gap: 2vh;
}

div#emploi > div.wrapper > div > table{
    margin-top: 4vh;
    margin-bottom: 4vh;
    text-align: center;
    box-shadow: 4px 0 10px rgb(0 0 0 / 0.3);
}

div#emploi > h1 {
    text-align: center;
    color: white;
}


div#emploi hr{
    width:15vh;
    border: none;
    border-bottom: 2px solid rgba(119, 119, 119, 0.25);
    margin-bottom: 5vh;
}

div#emploi > div.wrapper > div > table > thead{
    background: black;
    color: var(--white);
}


.button-3:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
}


.button-3 {
    background-color: var(--green);
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    color: #fff;
    width: 60%;
    margin:0 auto;
    display:block;
  }

.button-3:hover {
    background-color: #2c974b;
}

.button-3:focus {
    box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
    outline: none;
}

.button-3:disabled {
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, .1);
    color: rgba(255, 255, 255, .8);
    cursor: default;
}

.button-3:active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

h3{
    color: white;
}

@media screen and (max-width: 768px) {
    div#emploi > div.wrapper{
        flex-direction: column;
        row-gap: 2vh;
    }

    div.cardList > div.wrapper{
        flex-direction: column;
        row-gap: 2vh;
    }

}
</style>