<template>
    <div class="cart">
        <div class="contenu">
            <h1>{{ name }} <br> {{ surname }}</h1>
            <p>{{ desc }}</p>
        </div>
        <img :src="imagesrc" alt="" class="bg">
    </div>
</template>

<script>
    export default {
        name: 'CardCollaborator',
        props: {
            name: String,
            surname: String,
            desc: String
        },
        data() {
            return {
                imagesrc: require(`@/assets/collaborator/${this.name.toLocaleLowerCase()}.jpg`)
            }
        },
    }
</script>

<style scoped>

    .cart{
        position: relative;
        height: 300px;
        border-radius: 10px;
        overflow: hidden;
        flex: 0 0 calc(25% - 2em);
    }
    .contenu {
        position: absolute;
        width: 100%;
        height: 0;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        text-align: center;
        z-index: 1;
        background: #5a9b5c;
        color: white;
        transition: height 0.5s;
        overflow-y: scroll;
    }

        /* Custom scrollbar */
    .contenu::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    }

    .contenu::-webkit-scrollbar-track {
    background: #5a9b5c; /* Track color */
    }

    .contenu::-webkit-scrollbar-thumb {
    background: var(--black); /* Thumb color */
    border-radius: 4px; /* Border radius */
    }

    .contenu::-webkit-scrollbar-thumb:hover {
    background: var(--black); /* Thumb color on hover */
    }



    div.contenu h1{
        font-size: 1.5em;
        margin: 0;
        padding: 0;
        text-transform: capitalize;
        text-align: right;
        padding: 0.5em;
    }

    div.contenu p{
        font-size: 0.8em;
        margin: 0;
        padding: 0;
        padding: 1em;
        text-align: left;
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .cart:hover .contenu {
        height: 70%;
    }

    @media only screen and (max-device-width: 600px ){
        .cart{
            flex: 1 0 calc(50% - 4em);
        }
    }

</style>

