<template>
    <header>
        <div animated fadeDown class="container">
            <a href="/"><img class="logo" src="../../assets/logo.png" alt="Logo falcare"></a>
            <div ref="phonemenu" class="phonemenu">
                <ul class="menu">
                    <li><a href="accueil">Accueil</a></li>
                    <li><a href="gr_therapeutique">Groupes thérapeutiques</a></li>
                    <li><a href="facturation">Facturation</a></li>
                    <li><a href="emploi">Emplois</a></li>
                    <li><a href="contact">Contact</a></li>
                </ul>
                <i id="closemenubtn"  @click="hide" class="material-icons md-100">close</i>
            </div>
            <i id="menubtn" @click="show" class="material-icons md-150">menu</i>
        </div>
    </header> 
</template>

<script>

export default {
    name: 'MainFooter',
    data() {
        return {
            //
        }
    },
    methods: {
        show(){
            this.$refs.phonemenu.style.width = "100%";
            this.$refs.phonemenu.style.padding = "2vh";
        },
        hide(){
            this.$refs.phonemenu.style.width = "0px";
            this.$refs.phonemenu.style.padding = "0px";
        }
    },
}

</script>

<style scoped>
    header{
        width: 100%;
        height: 12vh;
        padding-top: 2vh;
        min-height: 100px;
        color: var(--black);
    }

    header div.container{
        width: fit-content;
        margin-left: 5em;
    }
    div.phonemenu{
        display: inline-block;
    }

    i#menubtn {
        display: none;
    }

    i#closemenubtn {
        display: none;
    }

    img.logo{
        height: 5vh;
    }

    ul.menu{
        display: inline-block;
        padding-left: 5em;
        color: var(--black);
    }

    ul.menu li{
        display: inline-block;
        margin-left: 1vw;
        font-weight: bold;
        transition: 0.3s;
        border-bottom: 2px solid rgba(84, 235, 109, 0);
        color: var(--black);
    }

    ul.menu li.flag{
        color: rgb(103, 112, 103);
    }

    ul.menu li:hover{
        border-bottom: 2px solid rgb(66, 187, 86);
    }

    @media only screen and (max-device-width: 600px ){

        i#menubtn {
            display: block;
            float: right;
        }
        i#closemenubtn {
            display: inline-block;
        }

        ul.menu li{
            display: block;
        }

        ul.menu{
            flex: 90%;
            padding-left: 0;
        }

        header div.container{
            width: 100%;
            height: 100%;
            max-width: 2000px;
            margin: 0;
        }

        img.logo{
            height: 100%;
        }

        div.phonemenu{
            position: fixed;
            display: flex;
            overflow: hidden;
            width: 0px;
            height: 101vh;
            transform: translate(-4%, -12.1%);
            background: var(--white);
            z-index: 10;
            font-size: 2em;
            align-items: flex-start;
            transition: 0.5s;
        }
        .material-icons.md-100 { font-size: 3em; }
        .material-icons.md-150 { font-size: 4.5em; }

    }
</style>