<template>
    <aside class="section-title-bar">
        <div class="section-title-line"></div>
        <h1 class="section-title"> <span class="point"></span> L'estime de soi</h1>
        <h1 class="section-title"> <span class="point"></span> Gestion des emotion</h1>
        <h1 class="section-title"> <span class="point"></span> Education Thérapeutique</h1>
        <h1 class="section-title"> <span class="point"></span> La libre parole</h1>
        <h1 class="section-title"></h1>
    </aside>
</template>

<script>
    export default {
        name: 'VerticalBarGrT',
        data() {
            return {
                //
            }
        },
        methods: {
            scrollHandler() {
                const height = document.querySelector('div.grsection') 
                const currentPos = window.pageYOffset + (height.offsetHeight)/2;

                // Get the section title bar and line elements
                // const titleBar = document.querySelector(".section-title-bar");
                const titleLine = document.querySelector(".section-title-line");
                const points = document.querySelectorAll(".point");

                points.forEach(point => {
                    var rect = point.getBoundingClientRect()
                    let positionY = rect.top + window.pageYOffset - (height.offsetHeight)/2;
                    if (currentPos > positionY){
                        point.style.borderColor = `var(--green)`;
                    } else{
                        point.style.borderColor = `lightgray`;
                    }
                });

                // Calculate the relative position of the current position
                const relativePos = (currentPos / (document.body.scrollHeight - window.innerHeight)) * 100;

                // Change the color of the line and point using a linear gradient
                titleLine.style.background = `linear-gradient( to bottom, var(--green)${relativePos -0.5}%, lightgray ${relativePos}%)`;
            }
        },
        mounted() {
            window.addEventListener("scroll", this.scrollHandler);

        },
        unmounted() {
            window.removeEventListener("scroll", this.scrollHandler);
        }
    }
</script>


<style>
   .section-title-bar {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-left: 20px;
      width: 200px;
      min-height: 3200px;
    }

    .section-title-line {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 3px;
      background: linear-gradient( to bottom,var(--green) 0%, lightgray 0%);
    }

    .section-title {
      position: relative;
      margin: 0;
      padding: 20px 0;
      font-size: 20px;
      font-weight: bold;
      color: black;
      z-index: 1;
    }

    .point {
      content: "";
      position: absolute;
      top: -10px;
      left: calc(50% + 2px) ;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: lightgray;
      border-radius: 50px;
      z-index: 2;
    }

    @media only screen and (max-device-width: 600px ){
        .section-title-bar {
            display: none;
        }
    }
</style>